<template>

  <div v-if="bootstrapStore.isInit" class="bg-slate-100">
    <div
      class="relative border-slate-300 border mx-auto max-w-2xl flex flex-col w-full items-stretch border-b border-sand"
    >
      <div
        class="flex items-center justify-between border-b border-gray-300 shadow border-marble h-16 bg-white"
      >
        <div class="flex flex-row items-center mx-4" @click="toHome">
          <i
            v-if="!isHome"
            class="fa-solid fa-chevron-left text-xl text-gray-900 mr-2"
          ></i>
          <span class="text-lg text-gray-900 font-semibold">{{content?.title}} </span>
          <span
            v-if="eventStore.getPackage !== 'free'"
            class="text-sm font-light ml-4 text-gray-500"
            >{{content?.description}}</span
          >
        </div>

        <div class="flex items-center mx-2 gap-x-2 relative flex-shrink-0">
          <!-- <div class="relative inline-block w-full text-gray-700">
            <select class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600  rounded-lg appearance-none focus:outline-0 focus:ring-0" @change="(e) => setLang(e.target.value)">
              <option value="th" :selected="lang === 'th'">🇹🇭</option>
              <option value="en" :selected="lang === 'en'">🇬🇧</option>
            </select>
            <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
            </div>
          </div> -->

          <button
            @click="handleUpgrade"
            v-if="eventStore.getPackage == 'free'"
            class="px-4 py-2 bg-blue-700 rounded-lg shadow-xl text-white"
          >
            UPGRADE
          </button>
        </div>
      </div>

      <div
        v-if="eventStore.getPackage == 'free'"
        class="text-center bg-yellow-100"
      >
        <div class="text-xs text-gray-700 py-2">
          คุณกำลังทดลองใช้งาน (รองรับเขียนคำอวยพร 10 รูป)
        </div>
        <div class="bg-white border-sand border-b border-solid"></div>
      </div>

      <div
        v-if="!eventStore.isOwner && authStore.user?.eventId"
        class="text-center bg-yellow-100"
      >
        <div class="text-xs text-gray-700 py-2">
          Viewer Mode
        </div>
        <div class="bg-white border-sand border-b border-solid"></div>
      </div>      

      <div class="min-h-screen pb-16">
        <slot />
      </div>

      <!-- FOOTER -->
      <div
        class="z-40 flex border flex-grow mx-auto max-w-2xl bg-white border-sand border-t border-solid fixed inset-x-0 bottom-0 overflow-x-auto h-16 gap-2"
      >
        <a
          v-for="button in footerBottons"
          :key="button.title"
          @click="navigateTo(button.link)"
          :class="button.active ? 'border-b-4 border-black' : ''"
          class="inline-block flex focus:outline-none outline-transparent group items-center justify-center relative text-center py-3 flex-1"
          data-testid="Route"
        >
          <i :class="button.icon"></i>
          <span class="text-base ml-2"> {{ button.title }}</span>
          <p v-if="button?.amount" class="absolute top-1.5 right-0 md:right-8 bg-yellow-400 rounded-full px-1.5 text-xs">{{button?.amount}}</p>
        </a>
      </div>
    </div>
  </div>

  <div v-else-if="bootstrapStore.failed">
    <div class="flex items-center justify-center h-screen">
      <!-- <div class="text-2xl">Please access from LINE Offcial Account</div> -->
      <div class="text-2xl">Redirect To Line Login...</div>
    </div>
  </div>

  <div v-else>
    <div class="flex flex-col h-screen justify-between">
      <div class="flex items-center justify-center flex-grow">
        <div class="text-2xl">Loading...</div>
      </div>
      <div class="flex justify-center pb-4">
        <span class="text-gray-500 text-sm">version {{version}}</span>
      </div>
    </div>
  </div>

    <!-- <MainMenuModal v-if="!isVisableMainMenuModal" :isOpenUploadModal="isShowModal" @closed="closeMainModal"/> -->
    <GlobalLoading />
</template>

<script setup>
import _ from 'lodash';
import { useContentHomeAndUtils } from '~/composables/content/v2/useContentHomeAndUtils'
const { lang, content, setLang } = useContentHomeAndUtils('nav')
import packageJson from '~/package.json'
import pluginRedirect from '~/plugins/custom/redirect-pages'

onMounted(() => {
  pluginRedirect()
}) 


const bootstrapStore = useBootstrapStore();
bootstrapStore.init();

// //CHECK DEV MODE
const appStateStore = useAppStateStore();
const eventStore = useEventStore();
const authStore = useAuthStore();
const route = useRoute();

const version = ref(packageJson.version)

// const preweddingStore = usePreweddingStore();
// const wishStore = useWishStore();

// if (true) {
//   //   isLoading.value = false;
//   // appStateStore.mainLoadMockUp();
//   // preweddingStore.main();
//   // wishStore.fetchWishesRealtime();
// }

// const isHome = computed(() => route.path === "/main" || route.path === "/");
const isHome = computed(() => route.path === "/v2/home" || route.path === "/");
const isShowModal = computed(()=>appStateStore.isShowMainModal)
const isVisableMainMenuModal = computed(()=>eventStore.event?.isVisableModal || false)
onMounted(async() => {
  // appStateStore.mainLoadMockUp();
  // preweddingStore.main();
  // wishStore.fetchWishesRealtime();


  
});


const toHome = () => {
  if(_.includes(useRoute().fullPath , '/pairEvent')) {
    return navigateTo("/v2/wish")
  }
  if(_.includes(useRoute().fullPath , 'v2/wishes/sortInfo')) {
    return navigateTo("/wish/wishes")
  }
  if(_.includes(useRoute().fullPath , '/v2/wish/')) {
    return navigateTo("/v2/wish")
  }
  if(_.includes(useRoute().fullPath , '/v2/realtimeSlides/')) {
    return navigateTo("/v2/realtimeSlides")
  }
  if(_.includes(useRoute().fullPath , '/v2/promptpay/')) {
    return navigateTo("/v2/promptpay")
  }
  if(_.includes(useRoute().fullPath , '/v2/lineNotify/')) {
    return navigateTo("/v2/home")
  }
  if(_.includes(useRoute().fullPath , '/v2/users/')) {
    return navigateTo("/v2/home")
  }
  if(_.includes(useRoute().fullPath , '/v2/')) {
    return navigateTo("/v2/home")
  }

  return navigateTo("/v2/home")

  // navigateTo("/main");
}
const closeMainModal = async()=>{
  appStateStore.setShowMainModal(false)
  
}

const footerBottons = ref(content?.value?.list)

const handleUpgrade = () => {
  // return alert("กรุณาติดต่อเจ้าหน้าที่เพื่อสั่งซื้อpackage");
  // navigateTo("/package/checkout");
  navigateTo("/package/price");
};

const handleSelectLang = (targetLang) => {
  setLang(targetLang)
}

watchEffect(() => {
  footerBottons.value.forEach((button) => {
    button.active = button.link === route.path;
  });

  //get current path and compare to subPath
  footerBottons.value.forEach((button) => {
    button.active = button.subPath.includes(route.path);
  });

  // update footer button list
  footerBottons.value = content?.value?.list
});
</script>
