
import _ from 'lodash'


export default () => {
 
    const REDIRECT_PATH = {
        "purchased": "/v2/shippingAndPromptpay",
        "register_form": "/v2/register",
        "main": "/v2/home"
    }

 
    const store = useEventStore();
    let previousPage = 'main'
    const unsub = store.$subscribe((mutation, state) => {
        let newPage = _.first(state.event?.pages) || "main";
        if (previousPage !== newPage) {
            navigateTo(REDIRECT_PATH[newPage])
            setTimeout(() => {
                navigateTo(REDIRECT_PATH[newPage])
            }, 2000);
            previousPage = newPage
        };
    })


    onUnmounted(() => {
        debugger
        unsub()  
    })

}